<template>
  <div class="h-bg">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-img
            height="250px"
            :src="logo"
            aspect-ratio="2"
            contain
          />
          <div class="text">
            <p class="h-font-size mb-0">
              Just One Tap
            </p>
            <p class="h-font-size mb-0 text-right">
              All Health App
            </p>
          </div>
          <v-img
            :src="vectorImage"
            aspect-ratio="2"
            contain
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <h2>Sign Up</h2>
          <p style="font-size: 18px">
            It’s free! Only <span class="font-weight-bold">Doctors</span> can join.
          </p>

          <ValidationObserver
            ref="registrationFormObserver"
            v-slot="{ handleSubmit }"
          >
            <v-form
              ref="registrationForm"
              @submit.prevent="handleSubmit(submitRegistration)"
            >
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{errors}"
                    vid="full_name"
                    rules="required"
                    name="Full Name"
                  >
                    <v-text-field
                      v-model="formData.name"
                      hide-details="auto"
                      outlined
                      dense
                      dark
                      color="white"
                      label="Full Name"
                      maxlength="20"
                      class="mb-4"
                      name="full_name"
                      :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{errors}"
                    vid="phone"
                    rules="required"
                    name="Phone Number"
                  >
                    <v-text-field
                      v-model="formData.phoneNo"
                      hide-details="auto"
                      outlined
                      dense
                      dark
                      color="white"
                      label="Phone Number"
                      maxlength="20"
                      class="mb-4"
                      name="phone"
                      :error-messages="errors"
                    />
                  </ValidationProvider>

                  <v-menu
                    ref="dateofbirth"
                    v-model="dateOfBirth"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <ValidationProvider
                        v-slot="{errors}"
                        vid="date_of_birth"
                        name="Date of Birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="formData.dob"
                          name="date_of_birth"
                          label="Date of Birth"
                          outlined
                          dense
                          readonly
                          color="white"
                          dark
                          hide-details="auto"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          :error-messages="errors"
                          v-on="on"
                          @click:append="dateOfBirth = !dateOfBirth"
                        />
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="formData.dob"
                      no-title
                      @input="dateOfBirth = false"
                    />
                  </v-menu>

                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        class="pl-0"
                      >
                        <ValidationProvider
                          v-slot="{errors}"
                          vid="bm_dc_type"
                          name="BM & DC Type"
                          rules="required"
                        >
                          <v-select
                            v-model="formData.docType"
                            name="bm_dc_type"
                            hide-details="auto"
                            :items="items"
                            label="BM & DC Type"
                            required
                            outlined
                            dark
                            color="white"
                            :error-messages="errors"
                            dense
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="6"
                        class="pr-0"
                      >
                        <ValidationProvider
                          v-slot="{errors}"
                          vid="bm_dc_number"
                          rules="required"
                          name="BM & DC Number"
                        >
                          <v-text-field
                            v-model="formData.bmdcNo"
                            hide-details="auto"
                            outlined
                            dense
                            dark
                            color="white"
                            label="BM & DC Number"
                            maxlength="20"
                            class="mb-4"
                            name="bm_dc_number"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-container>

                  <ValidationProvider
                    v-slot="{errors}"
                    vid="email"
                    rules="required"
                    name="E-mail"
                  >
                    <v-text-field
                      v-model="formData.email"
                      hide-details="auto"
                      outlined
                      dense
                      dark
                      color="white"
                      type="email"
                      label="E-mail"
                      maxlength="20"
                      class="mb-4"
                      name="email"
                      :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{errors}"
                    vid="password"
                    rules="required"
                    name="Password"
                  >
                    <v-text-field
                      v-model="formData.password"
                      hide-details="auto"
                      outlined
                      dense
                      dark
                      color="white"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      maxlength="20"
                      class="mb-4"
                      name="password"
                      :error-messages="errors"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="showPassword = !showPassword"
                    />
                  </ValidationProvider>

                  <v-checkbox
                    v-model="terms_and_conditions"
                    name="terms_and_conditions"
                    color="white"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="I agree to the Terms of Service and Privacy Policy"
                    required
                    dense
                    dark
                  />
                </v-col>

                <v-col
                  cols
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <v-btn
                    block
                    color="white"
                    elevation="2"
                    rounded
                    dark
                    outlined
                    type="submit"
                  >
                    Signup
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar.action"
        :timeout="snackbar.timeout"
        top
        right
        :color="snackbar.color"
        class="font-weight-bold"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import logo from '@/assets/images/logo.png';
import vectorImage from '@/assets/images/vectorimage.png';
import {postData} from "@/helpers/apiRuquests";
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: "The {_field_} field is required."
});

export default {
    name: "Registration",
    components: {ValidationObserver, ValidationProvider},
    data: () => ({
        dateOfBirth: false,
        showPassword: false,
        terms_and_conditions: '',
        formData: {
            name: '',
            dob: '',
            phoneNo: '',
            password: '',
            bmdcNo: '',
            email: '',
            docType: null,
            type: 'doctor'
        },
        logo: logo,
        vectorImage: vectorImage,
        items: [
            'general',
            'dentist',
        ],
        snackbar: {
            action: false,
            text: '',
            timeout: 5000,
            color: null
        },
    }),
    methods: {
        submitRegistration() {
            this.loading = true;
            let params = this.formData;
            postData(
                "registration", params,
                () => {
                    this.loading = false;
                    this.successResponse();

                },
                error => {
                    this.errorResponse(error);
                    this.loading = false;

                }
            );

        },
        successResponse() {
            this.snackbar = {
                action: true,
                text: "Successfully Registered",
                color: "success"
            };

            this.$nextTick(() => {
                this.$refs.registrationFormObserver.reset();
            });

            this.formData = {
                name: '',
                dob: '',
                phoneNo: '',
                password: '',
                bmdcNo: '',
                email: '',
                docType: null,
                type: 'doctor'
            };
        },
        errorResponse(error) {
            this.loading = false;
            this.snackbar = {
                action: true,
                text: error.response.data.Message,
                color: "error"
            };
        }
    },
}
</script>

<style scoped lang="scss">
.h-bg {
    background-color: #467DE4;
    height: 100%;
    color: #fff;

    .h-font-size {
        font-size: 24px;
        width: 40%;
        margin: 0 auto;
    }

    .lb {
        font-size: 18px;
    }
}

.v-label.theme--light {
    color: white !important;
}
</style>